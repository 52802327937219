import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    campaigns: null, // list of campaigns
    clients: null,
    companies: null,
    campaign: null, // single campaign
    campaignEvents: null, // events for single campaign
  },
  getters: {
    getAllCampaigns(state) {
      return state.campaigns
    },
    getAllClients(state) {
      return state.clients
    },
    getAllCompanies(state) {
      return state.companies
    },
    getCampaign(state) {
      return state.campaign
    },
    getCampaignEvents(state) {
      return state.campaignEvents
    },
  },
  mutations: {
    SET_CAMPAIGNS(state, campaigns) {
      state.campaigns = campaigns
    },
    SET_CLIENTS(state, clients) {
      state.clients = clients
    },
    SET_COMPANIES(state, companies) {
      state.companies = companies
    },
    SET_CAMPAIGN(state, campaign) {
      state.campaign = campaign
    },
    SET_CAMPAIGN_EVENTS(state, campaignEvents) {
      state.campaignEvents = campaignEvents
    },
  },
  actions: {
    fetchAllCampaigns({ commit }) {
      useJwt.getAllCampaigns()
        .then(result => commit('SET_CAMPAIGNS', result.data.data))
    },
    fetchAllCompanies({ commit }) {
      useJwt.getCompaniesAsRoot()
        .then(result => commit('SET_COMPANIES', result.data.data))
    },
    fetchClientsAsRoot({ commit }, companyId) {
      useJwt.getClientsAsRoot(companyId)
        .then(result => commit('SET_CLIENTS', result.data.data))
    },
    fetchCampaignsAsRoot({ commit }, companyId) {
      useJwt.getCampaignsAsRoot(companyId)
        .then(result => commit('SET_CAMPAIGNS', result.data.data))
    },
    fetchCampaign({ commit }, campaignId) {
      return new Promise((resolve, reject) => {
        useJwt.getCampaign(campaignId)
          .then(result => {
            commit('SET_CAMPAIGN', result.data.data)
            resolve(result.data.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      })
    },
    fetchCampaignAsRoot({ commit }, campaignId) {
      useJwt.getCampaignAsRoot(campaignId)
        .then(result => commit('SET_CAMPAIGN', result.data.data))
    },
    fetchCampaignEvents({ commit }, campaignId) {
      useJwt.getCampaignEvents(campaignId)
        .then(result => commit('SET_CAMPAIGN_EVENTS', result.data.data))
    },
    fetchCampaignEventsAsRoot({ commit }, campaignId) {
      useJwt.getCampaignEventsAsRoot(campaignId)
        .then(result => commit('SET_CAMPAIGN_EVENTS', result.data.data))
    },
  },
}
