export default [

  /* CAMPAIGNS ROUTES */
  { // read all
    path: '/campaigns',
    name: 'browse-campaigns',
    component: () => import('@/views/campaigns/BrowseCampaigns.vue'),
    meta: {
      resource: 'Campaign',
      action: 'browse',
    },
  },
  { // create
    path: '/campaigns/create',
    name: 'create-campaign',
    component: () => import('@/views/campaigns/AddCampaign.vue'),
    meta: {
      resource: 'Campaign',
      action: 'create',
    },
  },
  { // create as root user
    path: '/campaigns/create/company/:companyId',
    name: 'create-campaign-root',
    component: () => import('@/views/campaigns/AddCampaign.vue'),
    meta: {
      resource: 'Campaign',
      action: 'create',
    },
  },
  { // read
    path: '/campaigns/id/:id',
    name: 'view-campaign',
    component: () => import('@/views/campaigns/ViewCampaign.vue'),
    meta: {
      resource: 'Campaign',
      action: 'read',
    },
  },
  { // update
    path: '/campaigns/id/:id/edit',
    name: 'edit-campaign',
    component: () => import('@/views/campaigns/EditCampaign.vue'),
    meta: {
      resource: 'Campaign',
      action: 'update',
    },
  },

  /* EVENT CALENDAR */
  {
    path: '/campaigns/id/:id/calendar',
    name: 'event-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      resource: 'Campaign',
      action: 'update',
    },
  },

  /* CARRIER DESIGN */
  { // create
    path: '/campaigns/id/:id/design/create',
    name: 'create-design',
    component: () => import('@/views/campaigns/design/CreateDesign.vue'),
    meta: {
      resource: 'Campaign',
      action: 'update',
    },
  },
]
