export default [

  /* PRINTER ROUTES */

  { // browse
    path: '/printers',
    name: 'browse-printers',
    component: () => import('@/views/printers/BrowsePrinter.vue'),
    meta: {
      resource: 'Printer',
      action: 'browse',
    },
  },
  { // read
    path: '/printers/view/:id',
    name: 'view-printer',
    component: () => import('@/views/printers/ViewPrinter.vue'),
    meta: {
      resource: 'Printer',
      action: 'read',
    },
  },
  { // update
    path: '/printers/edit/:id',
    name: 'edit-printer',
    component: () => import('@/views/printers/EditPrinter.vue'),
    meta: {
      resource: 'Printer',
      action: 'update',
    },
  },
  { // create
    path: '/printers/create',
    name: 'create-printer',
    component: () => import('@/views/printers/AddPrinter.vue'),
    meta: {
      resource: 'Printer',
      action: 'create',
    },
  },
]
