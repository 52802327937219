export default [

  /* CLIENT ROUTES */

  { // browse
    path: '/clients',
    name: 'browse-client',
    component: () => import('@/views/clients/BrowseClient.vue'),
    meta: {
      resource: 'Client',
      action: 'browse',
    },
  },
  { // read
    path: '/clients/view/:id',
    name: 'view-client',
    component: () => import('@/views/clients/ViewClient.vue'),
    meta: {
      resource: 'Client',
      action: 'read',
    },
  },
  { // update
    path: '/clients/edit/:id',
    name: 'edit-client',
    component: () => import('@/views/clients/EditClient.vue'),
    meta: {
      resource: 'Client',
      action: 'update',
    },
  },
  { // create
    path: '/clients/create',
    name: 'create-client',
    component: () => import('@/views/clients/AddClient.vue'),
    meta: {
      resource: 'Client',
      action: 'create',
    },
  },
]
