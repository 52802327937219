import apiJwtConfig from './jwtConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...apiJwtConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns) {
    this.axiosIns = axiosIns

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        // set base url
        config.baseURL = this.jwtConfig.baseUrl // eslint-disable-line no-param-reassign

        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        /* const { config, response: { status } } = error */
        const { response } = error
        /* const originalRequest = config */

        // if (status === 401) {
        if (response && response.status === 401) {
          console.log('useJwt 401 Unauthorized', response)

          // Clear localstorage
          localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('packagedUserData')
          localStorage.removeItem('companyData')
          localStorage.removeItem('userData')

          // Redirect to login page
          window.location.href = '/login'

          /* if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest */
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  /* AUTHENTICATION */

  login(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.login}`, ...args)
  }

  register(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.register}`, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.refresh}`, {
      refreshToken: this.getRefreshToken(),
    })
  }

  /* USERS */

  addUser(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.myCompany}/invite`, ...args)
  }

  /* COMPANY */

  getMyCompany() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.myCompany}`)
  }

  updateMyCompany(...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.myCompany}`, ...args)
  }

  getMyCompanyUsers() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.myCompany}/users`)
  }

  sendMyCompanyInvite(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.myCompany}/invite`, ...args)
  }

  /* CLIENT */

  getAllClients() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.campaigns}/clients/all`)
  }

  getClient(clientId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.campaigns}/client/${clientId}`)
  }

  createClient(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.campaigns}/client`, ...args)
  }

  updateClient(clientId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.campaigns}/client/${clientId}`, ...args)
  }

  /* CAMPAIGN */

  getAllCampaigns() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.campaigns}`)
  }

  getCampaign(campaignId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.campaigns}/${campaignId}`)
  }

  getCampaignEvents(campaignId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.campaigns}/${campaignId}/rules`)
  }

  createCampaign(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.campaigns}`, ...args)
  }

  updateCampaign(campaignId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.campaigns}/${campaignId}`, ...args)
  }

  deleteCampaign(campaignId) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.campaigns}/${campaignId}`)
  }

  createCampaignEvent(campaignId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.campaigns}/${campaignId}/rules`, ...args)
  }

  editCampaignEvent(campaignEventId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.campaigns}/rules/${campaignEventId}`, ...args)
  }

  deleteCampaignEvent(campaignEventId) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.campaigns}/rules/${campaignEventId}`)
  }

  setCampaignEventDefault(campaignEventId) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.campaigns}/rules/${campaignEventId}/default`)
  }

  addCampaignQrCode(campaignId) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.campaigns}/${campaignId}/qrcode`)
  }

  addCampaignQrCodeAsRoot(campaignId) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.root}/campaigns/${campaignId}/qrcode`)
  }

  /* CARRIER DESIGN */

  getDesignImageLocations(...args) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.designImage}/locations`, ...args)
  }

  getQrCodePlacements(...args) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.designImage}/qrcode/placements`, ...args)
  }

  addDesignImage(file, campaignId, locationId) {
    const formData = new FormData()
    formData.append('file', file)
    return this.axiosIns(
      {
        method: 'post',
        url: `${this.jwtConfig.endpoints.campaigns}/${campaignId}/designimage/location/${locationId}`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
  }

  deleteDesignImage(campaignId, locationId) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.campaigns}/${campaignId}/designimage/location/${locationId}`)
  }

  createPrintableDesignImage(campaignId, locationId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.campaigns}/${campaignId}/designimage/printable/${locationId}`, ...args)
  }

  createFinalDesignImage(campaignId, locationId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.campaigns}/${campaignId}/designimage/${locationId}/printable/finalize`, ...args)
  }

  deleteFinalDesignImage(campaignId, locationId) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.campaigns}/${campaignId}/designimage/${locationId}/printable/finalize`)
  }

  addDesignImageAsRoot(file, campaignId, locationId) {
    const formData = new FormData()
    formData.append('file', file)
    return this.axiosIns(
      {
        method: 'post',
        url: `${this.jwtConfig.endpoints.root}/campaigns/${campaignId}/designimage/location/${locationId}`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
  }

  deleteDesignImageAsRoot(campaignId, locationId) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.root}/campaigns/${campaignId}/designimage/location/${locationId}`)
  }

  createPrintableDesignImageAsRoot(campaignId, locationId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.root}/campaigns/${campaignId}/designimage/printable/${locationId}`, ...args)
  }

  createFinalDesignImageAsRoot(campaignId, locationId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.root}/campaigns/${campaignId}/designimage/${locationId}/printable/finalize`, ...args)
  }

  deleteFinalDesignImageAsRoot(campaignId, locationId) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.root}/campaigns/${campaignId}/designimage/${locationId}/printable/finalize`)
  }

  /* PRINTERS */

  getPrinters(...args) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/printers`, ...args)
  }

  getPrintersAsRoot(...args) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.root}/printers`, ...args)
  }

  createPrinterAsRoot(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.root}/printer/invite`, ...args)
  }

  getPrinterAsRoot(printerId) { // get one printer
    return this.axiosIns.get(`${this.jwtConfig.endpoints.root}/printers/${printerId}`)
  }

  /* ADMIN ROOT */

  createCompanyAsRoot(...args) { // create one company
    return this.axiosIns.post(`${this.jwtConfig.endpoints.root}/company/`, ...args)
  }

  getCompaniesAsRoot(...args) { // get all companies
    return this.axiosIns.get(`${this.jwtConfig.endpoints.root}/companies`, ...args)
  }

  getClientsAsRoot(companyId) { // get all clients for company
    return this.axiosIns.get(`${this.jwtConfig.endpoints.root}/campaigns/client/company/${companyId}`)
  }

  getClientAsRoot(clientId) { // get one client
    return this.axiosIns.get(`${this.jwtConfig.endpoints.root}/campaigns/client/${clientId}`)
  }

  createClientAsRoot(...args) { // create one client
    return this.axiosIns.post(`${this.jwtConfig.endpoints.root}/campaigns/client/company/${args[0].companyId}`, ...args)
  }

  getCampaignsAsRoot(clientId) { // get all campaigns for client
    return this.axiosIns.get(`${this.jwtConfig.endpoints.root}/campaigns/client/${clientId}/campaigns`)
  }

  getCampaignAsRoot(campaignId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.root}/campaigns/${campaignId}`)
  }

  getCampaignEventsAsRoot(campaignId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.root}/campaigns/${campaignId}/rules`)
  }

  createCampaignAsRoot(...args) {
    console.log('createCampaignAsRoot', args[0])
    return this.axiosIns.post(`${this.jwtConfig.endpoints.root}/company/${args[0].client_id}/campaigns`, ...args)
  }

  updateCampaignAsRoot(campaignId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.root}/campaigns/${campaignId}`, ...args)
  }

  deleteCampaignAsRoot(campaignId) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.root}/campaigns/${campaignId}`)
  }

  createCampaignEventAsRoot(campaignId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.root}/campaigns/${campaignId}/rules`, ...args)
  }

  editCampaignEventAsRoot(campaignEventId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.root}/campaigns/rules/${campaignEventId}`, ...args)
  }

  deleteCampaignEventAsRoot(campaignEventId) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.root}/campaigns/rules/${campaignEventId}`)
  }

  setCampaignEventDefaultAsRoot(campaignEventId) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.root}/campaigns/rules/${campaignEventId}/default`)
  }
}
